<template>
  <layout-navigation>
    <div :class="$style.text">
      Недостаточно прав доступа для просмотра страницы
    </div>
  </layout-navigation>
</template>

<script>
import LayoutNavigation from '@/layouts/navigation.vue';

export default {
  components: {
    LayoutNavigation,
  },
};
</script>

<style lang="scss" module>
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  width: 100%;
}
</style>
